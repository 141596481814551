<template>
  <div id="app">
    <v-app>
      <v-row justify="center">
        <v-dialog
          v-model="accountDialog.dialog"
          persistent
          scrollable
          max-width="600px"
        >
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card>
              <!-- 表单头部 -->
              <v-toolbar dark color="primary" v-lcx-drag>
                <v-toolbar-title>{{ accountDialog.title }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="accountDialog.dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <!-- 表单内容 start -->
                <v-container>
                  <v-row>
                    <input type="hidden" v-model="alarminfo.id" />
                    <v-col>
                      <!-- 方案名称:rules="nameRules" -->
                      <v-text-field
                        outlined
                        dense
                        v-model="alarminfo.name"
                        :counter="10"
                        :rules="nameRules"
                        label="*方案名称"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col>
                      <div id="select1" style="position: relative">
                        <!-- 告警级别 -->
                        <v-select
                          outlined
                          dense
                          v-model="alarminfo.alarmLevel"
                          :items="selects.LevelSelect"
                          item-text="name"
                          item-value="id"
                          :rules="[v => !!v || 'Item is required']"
                          label="*告警级别"
                          attach="#select1"
                          required
                        ></v-select>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <div
                        id="select2"
                        style="position: relative; margin-bottom: -25px"
                      >
                        <!-- 告警类型 -->
                        <v-select
                          outlined
                          dense
                          v-model="alarminfo.alarmType"
                          :items="selects.TypeSelect"
                          item-text="name"
                          item-value="id"
                          :rules="[v => !!v || 'Item is required']"
                          label="*告警类型"
                          attach="#select2"
                          required
                        ></v-select>
                      </div>
                    </v-col>

                    <v-col>
                      <div id="color" style="position: relative">
                        <v-select
                          outlined
                          dense
                          label="颜色"
                          ref="color"
                          :items="selects.ColorSelect"
                          :background-color="alarminfo.alarmColor"
                          item-value="name"
                          v-model="alarminfo.alarmColor"
                          item-text="label"
                          attach="#color"
                          required
                        >
                          <template v-slot:item="{ item, attrs, on }">
                            <v-list-item
                              v-bind="attrs"
                              v-on="on"
                              :id="attrs['aria-selected']"
                              :style="{ 'background-color': item.name }"
                            >
                              <v-list-item-title
                                :id="attrs['aria-labelledby']"
                              ></v-list-item-title>
                            </v-list-item>
                          </template>
                        </v-select>
                      </div>
                    </v-col>
                  </v-row>
                  <!-- <div style="padding-left: 20px;border-style: inset;margin-bottom: 10px;height:85px"> -->
                  <v-row>
                    <v-col>
                      <v-checkbox
                        v-model="alarminfo.active"
                        hide-details
                        label="是否启用"
                        color="red darken-3"
                        value=""
                      ></v-checkbox>
                    </v-col>
                    <v-col>
                      <v-checkbox
                        v-model="alarminfo.saveRecord"
                        hide-details
                        label="记录入库"
                        color="red darken-3"
                        value=""
                      ></v-checkbox>
                    </v-col>
                    <v-col>
                      <v-checkbox
                        v-model="alarminfo.pushMsg"
                        hide-details
                        label="消息推送"
                        color="red darken-3"
                        value=""
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-checkbox
                        v-model="alarminfo.enableSound"
                        hide-details
                        label="语音播报"
                        color="red darken-3"
                        value=""
                      ></v-checkbox>
                    </v-col>
                    <v-col>
                      <v-checkbox
                        v-model="alarminfo.sendMsg"
                        hide-details
                        label="发送短信"
                        color="red darken-3"
                        value=""
                      ></v-checkbox>
                    </v-col>
                    <v-col></v-col>
                  </v-row>
                  <!-- </div> -->
                  <v-row>
                    <v-textarea
                      outlined
                      name="input-7-4"
                      label="描述"
                      v-model="alarminfo.description"
                    ></v-textarea>
                  </v-row>
                </v-container>
                <span
                  class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                  id="kt_login_forgot"
                  >带 * 号的是必填项</span
                >
                <v-card-actions style="padding: 0px">
                  <v-spacer></v-spacer>
                  <v-btn
                    class="ma-2"
                    color="success"
                    type="submit"
                    @click.prevent="insertAndUpdateAlarm()"
                  >
                    保存
                  </v-btn>
                  <v-btn
                    color="blue-grey"
                    class="ma-2 white--text"
                    @click="accountDialog.dialog = false"
                  >
                    关闭
                  </v-btn>
                </v-card-actions>
              <!--  表单内容 end -->
              <!-- start:按钮区 -->

              <!-- end:按钮区 -->

              <!-- </form> -->
            </v-card>
          </v-form>
        </v-dialog>
      </v-row>
    </v-app>
  </div>
</template>
<style>
</style>
<script>
export default {
  name: "alarmAdd",
  data() {
    return {
      alarminfo: {
        id: null,
        name: "",
        alarmLevel: null,
        alarmType: null,
        alarmColor: "red",
        active: false,
        saveRecord: false,
        pushMsg: false,
        enableSound: false,
        sendMsg: false,
        description: ""
      },
      valid: true, //验证
      selects: {
        //下拉框数据
        LevelSelect: [],
        TypeSelect: [],
        ColorSelect: []
      },
      accountDialog: {
        title: "新增账户", //表单标题= "新增账户"
        dialog: false //是否显示表单
      },
      nameRules: [
        v => !!v || "方案名称不能为空",
        v => (v && v.length <= 10) || "名称长度必须小于等于10"
      ]
    };
  },
  watch: {
    "accountDialog.dialog": function (newV) {
      if (!newV) {
        this.resetAlarmInfo();
        this.$refs.form.resetValidation();
      }
    }
  },
  props: {
    selectList: Object
  },
  methods: {
    resetAlarmInfo: function () {
      this.alarminfo.id = null;
      this.alarminfo.name = "";
      this.alarminfo.alarmLevel = null;
      this.alarminfo.alarmType = null;
      this.alarminfo.alarmColor = "red";
      this.alarminfo.active = false;
      this.alarminfo.saveRecord = false;
      this.alarminfo.pushMsg = false;
      this.alarminfo.enableSound = false;
      this.alarminfo.sendMsg = false;
      this.alarminfo.description = "";
    },
    alarminitAdd: function (id) {
      //打开新增 修改页面
      var that = this;
      that.selects = this.selectList;
      if (id != null) {
        that.accountDialog.title = "修改告警方案";
        window.ApiService.get("/alarm/getAlarmById?id=" + id).then(
          ({ data }) => {
            Object.assign(this.alarminfo, data);
            if (this.alarminfo.alarmType != null)
              this.alarminfo.alarmType = this.alarminfo.alarmType.id;
            if (this.alarminfo.alarmLevel != null)
              this.alarminfo.alarmLevel = this.alarminfo.alarmLevel.id;
          }
        );
      } else {
        if (that.selects.LevelSelect.length > 0)
          that.alarminfo.alarmLevel = that.selects.LevelSelect[0].id;
        that.alarminfo.alarmType = that.selects.TypeSelect[0].id;
        that.accountDialog.title = "新增告警方案";
      }
      that.accountDialog.dialog = true;
    },
    insertAndUpdateAlarm() {
      if (!this.$refs.form.validate()) {
        return false;
      }
      var param = JSON.parse(JSON.stringify(this.alarminfo));
      if (this.alarminfo.id != null) {
        param["id"] = this.alarminfo.id;
        window.ApiService.post("/alarm/Programupdate", param).then(
          ({ msg }) => {
            this.resetAlarmInfo();
            window.Toast("success", msg);
            this.$emit("alarmPage");
          }
        );
      } else {
        window.ApiService.post("/alarm/InsertAlarm", param).then(
          ({ msg }) => {
            this.resetAlarmInfo();
            window.Toast("success", msg);
            this.$emit("alarmPage");
          }
        );
      }
      this.accountDialog.dialog = false;
    }
  }
};
</script>
