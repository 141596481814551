<template>
  <div>
    <!--begin::Advance Table Widget 2-->
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 pt-5">
        <v-container>
          <v-row>
            <v-col>
              <v-select
                dense
                outlined
                v-model="select"
                :items="typeList"
                item-text="name"
                item-value="id"
                return-object
                style="width: 300px"
                @change="selectChange((currentPage = 1))"
              ></v-select>
            </v-col>
            <v-col>
              <b-button
                variant="success"
                style="margin-right: 10px; float: right"
                @click.prevent="alarminitAdd(null)"
                >新增方案</b-button
              >
            </v-col>
          </v-row>
        </v-container>
      </div>

      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-3 pb-0">
        <!--begin::Table-->
        <div class="table-responsive">
          <table class="table table-vertical-center">
            <thead>
              <tr>
                <th class="pl-0 text-center" style="width: 50px">#</th>
                <th class="pl-0">方案名称</th>
                <th class="pl-0">方案级别</th>
                <th class="pl-0">告警类型</th>
                <th class="pl-0">提示颜色</th>
                <th class="pl-0">短信</th>
                <th class="pl-0">语音</th>
                <th class="pl-0">推送</th>
                <th class="pl-0">记录</th>
                <th class="pl-0">启用</th>
                <th class="pl-0">操作</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, i) in alarmList">
                <tr v-bind:key="i">
                  <!-- 序号 -->
                  <td class="pl-0 text-center">
                    {{ i + 1 }}
                  </td>

                  <!-- 方案名称  -->
                  <td class="pl-0">
                    <a
                      class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >
                      {{ item.name }}
                    </a>
                  </td>

                  <!-- 方案级别 -->
                  <td class="pl-0">
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.alarmLevel != null ? item.alarmLevel.name : "" }}
                    </span>
                  </td>

                  <!-- 告警类型 -->
                  <td class="pl-0">
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.alarmType != null ? item.alarmType.name : "" }}
                    </span>
                  </td>
                  <!-- 提示颜色 -->
                  <td class="pl-0">
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      <button
                        style="width: 20px; height: 20px"
                        :style="{ 'background-color': item.alarmColor }"
                      ></button>
                    </span>
                  </td>
                  <!-- 短信 -->
                  <td class="pl-0">
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      <v-icon medium color="teal darken-2">mdi-email</v-icon>
                      <input
                        type="checkbox"
                        v-if="item.sendMsg"
                        @change="checkboxclick($event, item.id, 'sendMsg')"
                        checked
                      />
                      <input
                        type="checkbox"
                        v-else-if="!item.sendMsg"
                        @change="checkboxclick($event, item.id, 'sendMsg')"
                      />
                    </span>
                  </td>
                  <!-- 语音 -->
                  <td class="pl-0">
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      <v-icon medium color="teal darken-2">mdi-voice</v-icon>
                      <input
                        type="checkbox"
                        v-if="item.enableSound"
                        @change="checkboxclick($event, item.id, 'enableSound')"
                        checked
                      />
                      <input
                        type="checkbox"
                        v-else-if="!item.enableSound"
                        @change="checkboxclick($event, item.id, 'enableSound')"
                      />
                    </span>
                  </td>
                  <!-- 推送 Push-->
                  <td class="pl-0">
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      <v-icon medium color="teal darken-2">mdi-send</v-icon>
                      <input
                        type="checkbox"
                        v-if="item.pushMsg"
                        @change="checkboxclick($event, item.id, 'pushMsg')"
                        checked
                      />
                      <input
                        type="checkbox"
                        v-else-if="!item.pushMsg"
                        @change="checkboxclick($event, item.id, 'pushMsg')"
                      />
                    </span>
                  </td>
                  <!-- 记录 history-->
                  <td class="pl-0">
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      <v-icon medium color="teal darken-2">mdi-history</v-icon>
                      <input
                        type="checkbox"
                        v-if="item.saveRecord"
                        @change="checkboxclick($event, item.id, 'saveRecord')"
                        checked
                      />
                      <input
                        type="checkbox"
                        v-else-if="!item.saveRecord"
                        @change="checkboxclick($event, item.id, 'saveRecord')"
                      />
                    </span>
                  </td>
                  <!-- 启用 use-->
                  <td class="pl-0">
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      <v-icon medium color="teal darken-2">monitor</v-icon>
                      <input
                        type="checkbox"
                        v-if="item.active"
                        @change="checkboxclick($event, item.id, 'active')"
                        checked
                      />
                      <input
                        type="checkbox"
                        v-else-if="!item.active"
                        @change="checkboxclick($event, item.id, 'active')"
                      />
                    </span>
                  </td>

                  <!-- 操作 use-->
                  <td class="pl-0">
                    <a
                      class="btn btn-icon btn-light btn-sm mx-3"
                      title="修改"
                      @click.prevent="alarminitAdd(item.id)"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <!--begin::Svg Icon-->
                        <inline-svg
                          title="修改"
                          src="media/svg/icons/Communication/Write.svg"
                        ></inline-svg>
                        <!--end::Svg Icon-->
                      </span>
                    </a>
                    <!-- 删除 -->
                    <a
                      class="btn btn-icon btn-light btn-sm"
                      title="删除"
                      v-if="item.bulidType"
                      @click.prevent="alarmDelete(item.id,item.alarmType.id)"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <!--begin::Svg Icon-->
                        <inline-svg
                          title="删除"
                          src="media/svg/icons/General/Trash.svg"
                        ></inline-svg>
                        <!--end::Svg Icon-->
                      </span>
                    </a>
                  </td>
                  <!-- 操作 -->
                </tr>
              </template>
            </tbody>
          </table>

          <!-- 翻页按钮 -->
          <div class="mt-3">
            <b-pagination
              first-number
              last-number
              size="lg"
              align="right"
              v-model="currentPage"
              :per-page="pageSize"
              :total-rows="rows"
            ></b-pagination>
          </div>
        </div>
        <!--end::Table-->
      </div>
      <!--end::Body-->
      <!--end::Advance Table Widget 2-->
    </div>
    <!-- start:新增告警方案 -->
    <alarmAdd
      ref="alarmAdd"
      @alarmPage="alarmPage"
      :selectList="selects"
    ></alarmAdd>
    <!-- end:新增告警方案 -->
  </div>
</template>

<style lang="scss">
.my-1 {
  padding-right: 10px;
}

.v-application .magenta {
  background-color: magenta !important;
  border-color: magenta !important;
}

.v-application .chocolate {
  background-color: chocolate !important;
  border-color: chocolate !important;
}

.v-application .aquamarine {
  background-color: aquamarine !important;
  border-color: aquamarine !important;
}

.v-application .lime {
  background-color: lime !important;
  border-color: lime !important;
}

.v-application .deeppink {
  background-color: deeppink !important;
  border-color: deeppink !important;
}

.v-application .gray {
  background-color: gray !important;
  border-color: gray !important;
}

.v-application .coral {
  background-color: coral !important;
  border-color: coral !important;
}
</style>
<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
import alarmAdd from "@/view/pages/alarm/include/alarm-add.vue";
export default {
  name: "alarm-list",
  data() {
    return {
      select: { id: "0", name: "请选择方案类型" },
      typeList: [{ id: "0", name: "请选择方案类型" }], //true
      selects: {
        //下拉框数据
        LevelSelect: [],
        TypeSelect: [],
        ColorSelect: []
      },
      rows: 0, //分页总数量
      currentPage: 1, //当前页
      pageSize: 15, //每页显示数量
      alarmList: [], //每页显示数据
      methodName: "alarmPage"
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  components: {
    alarmAdd
  },
  watch: {
    currentPage: function(val) {
      this.alarmPage(val);
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "基础配置", route: "" },
      { title: "告警方案管理" }
    ]);
    this.alarmType();
    this.alarmPage();
    this.getLevel();
    window.ApiService.get("/alarm/ColorList").then(({ data }) => {
      this.selects.ColorSelect = data;
    });
  },
  methods: {
    alarmType: function() {
      //告警类型列表
      window.ApiService.get("/alarm/InfoList").then(({ data }) => {
        this.typeList = this.typeList.concat(data);
        this.selects.TypeSelect = this.typeList.slice(1);
      });
    },
    alarmPage: function() {
      //首页
      window.ApiService.get(
        "/alarm/list?pageNum=" +
          this.currentPage +
          "&name=" +
          this.select.name
      ).then(({ data }) => {
        this.alarmList = data.pageinfo.list;
        this.rows = data.pageinfo.total;
        this.methodName = "alarmPage";
      });
    },
    checkboxclick: function(event, id, column) {
      //按钮启用
      var param = {};
      param["id"] = id;
      param[column] = event.target.checked;
      window.ApiService.post("/alarm/Programupdate", param).then(
        ({ msg }) => {
          window.Toast("success", msg);
        }
      );
    },
    selectChange() {
      if (this.currentPage === 1) {
        this.alarmPage();
      } else {
        this.currentPage = 1;
      }
    },
    getLevel: function() {
      window.ApiService.get("/alarm/levelList").then(({ data }) => {
        this.selects.LevelSelect = data;
      });
    },
    alarminitAdd: function(id) {
      //打开新增 修改页面
      this.$refs.alarmAdd.alarminitAdd(id);
    },
    alarmDelete(id,type) {
      Swal.fire({
        title:
          '<span style="color: black;">删除操作不可恢复，是否确定删除？<span>',
        showCancelButton: true,
        showDenyButton: true,
        confirmButtonText: `确定`,
        cancelButtonText: `取消`
      }).then(result => {
        if (result.isConfirmed) {
          window.ApiService.get("/alarm/DeleteAlarm?id=" + id+"&type="+type).then(
            ({ msg }) => {
              window.Toast("success", msg);
              this.alarmPage();
            }
          );
        }
      });
    }
  }
};
</script>
